import React from "react";
import styled from "styled-components";
import { colorTheme, H1, H2, H3 } from "../../../theme/Styles";
import CaptionedImage from "../../ui/captionedImage/CaptionedImage";
import {
  ContentSection,
  HeroImage,
  ProjectContainer,
  ProjectP,
  SummerySection,
  VideoContainer,
} from "../styles";
import { DesignActData } from "./DesignActData";

import TopBar from "../../ui/topBar/TopBar";
import { devices } from "../../../theme/devices";
import learningImage from "./assets/dayone.png"
import brainstormingImage from "./assets/brainstorming.png"
import earlysharingImage from "./assets/recap_graphic.png"
import positionmapImage from "./assets/position_map.png"
import sharingImage from "./assets/sharing.png"
import speculatingImage from "./assets/loglines.png"
import communicationsImage from "./assets/communications_evolution.png"
import commustructureImage from "./assets/community_structure.png"
import designactwebImage from "./assets/designact_web.png"
import worksheetsImage from "./assets/worksheets_1.png"
import workboardImage from "./assets/workboard_1.png"
import gatheringImage from "./assets/event_2.png"
import learningAbilitiesImage from "./assets/learning_abilities.jpg"
import collaborationImage from "./assets/collaboration.jpg"
import playStationImage from "./assets/play_station_artwork.jpg"

const Title = styled(H1)`
  width: 51vw;
  margin-bottom:1.1vw;
  color:#ce813d;
  @media ${devices.mobile} {
    width: 75vw;
  }
`;

const Frame = styled.img`
  width: 50vw;
  height: auto;
  margin: auto;
  margin: 5vw 20vw;
  text-align: center;
`;

const FrameContainer = styled.div`
  width: 90vw;
  background-color: ${colorTheme.lightGrey};
  margin: auto;
  margin-bottom: 8vw;
`;

export default function DesignAct(props: {}) {
  return (
    <ProjectContainer>
      <TopBar></TopBar>
      <HeroImage src={DesignActData.heroImage}></HeroImage>
      <SummerySection>
        <Title>{DesignActData.title}</Title>
        <ProjectP>
        During the peak of the Covid-19 pandemic, 
        a group of designers with Sri Lankan roots formed an online community to share their work and connect with each other. Motivated by our shared dream to a create a sustaining community, we embraced principles of listening, learning, and prototyping. Through engaging in various design activities and developing programs, we have made slow, but steady progress toward realizing our collective vision. 
        This project page highlights the key processes and programs that have helped us build our community. 

        
  
        </ProjectP>

        <H3>Duration</H3>
        <ProjectP> Dec 2020 - present </ProjectP>
        <H3>My Role</H3>
        <ProjectP> I co-founded the group and currently act as Community Manager. I oversee community strategy and development, while providing design research and facilitation expertise to our Interest Group initiatives.</ProjectP>
      </SummerySection>

      <ContentSection>
        <H2>Recognizing needs and dreaming of initiatives</H2>
        <ProjectP>
        On day one, we met online, and utilized new virtual whiteboard tools to brainstorm on the question: “What would your dream design community look, feel, or work like?”. Afterwards, we used the same platformto dive deeper into identified needs. With the help of a 4 quadrant position map, we sorted or proposed initiatives to identify which ones would have the greatest impact while consuming the lowest amount of resources. 
        Over the years, we keep coming back to these simple brainstorming, sorting and discussion tools. 

        </ProjectP>
      </ContentSection>
      <CaptionedImage caption={"Online whiteboard activity to uncover dreams, likes and wishes"} imageSrc={learningImage}></CaptionedImage>
      <CaptionedImage caption={"Diving deep into specific problems"} imageSrc={brainstormingImage}></CaptionedImage>
      <CaptionedImage caption={"Sorting initiatives using a position map"} imageSrc={positionmapImage}></CaptionedImage>


      <ContentSection>
      <H2>Sharing, speculating, strategizing</H2>
        <ProjectP>
        In our initial discussions, three key themes emerged that shaped our community's action focus: to share, to speculate, and to strategize. During our monthly meetings over the next six months, participants took turns to share one or two projects, explaining their design approach. 
        </ProjectP>
        <ProjectP>
          Speculation time included dreaming about our community's name, communication strategies, and program plans. In the strategy segment, we made concrete decisions about our community’s direction. 
        </ProjectP>
      </ContentSection>
      <CaptionedImage caption={"Visual summaries of the very first meetings that encompass sharing, speculating, and strategizing"} imageSrc={earlysharingImage}></CaptionedImage>
      <CaptionedImage caption={"A community member commences his sharing session about his typography work"} imageSrc={sharingImage}></CaptionedImage>
      <CaptionedImage caption={"A selection of responses to the 'logline' activity where we speculated about our community name and 'plot'"} imageSrc={speculatingImage}></CaptionedImage>
      <ContentSection>
        <H2> Shaping community strategy and outlook</H2>
        <ProjectP>
        From the start, we knew that to foster a strong sense of belonging, everyone needed to help shape our community. Initially, we named ourselves the generic "Designers’ Discussion" to build consensus on our community's identity and strategy gradually. Our early communications used minimal colors and amorphous shapes to emphasize that members were integral to shaping the community. Over several sessions, we established our foundational values, whom we served, and how we wanted to build structure. We eventually named ourselves DesignAct—a movement empowering designers and enthusiasts.
        </ProjectP>
      </ContentSection>
      <CaptionedImage caption={"Early communications reflected our evolving nature"} imageSrc={communicationsImage}></CaptionedImage>
      <CaptionedImage caption={"Imagining possible community structures"} imageSrc={commustructureImage}></CaptionedImage>
      <CaptionedImage caption={"Web landing page for designact.org with a synthesized, unfied vision for the community and placeholder branding"} imageSrc={designactwebImage}></CaptionedImage>
      <ContentSection>
        <H2>Prototyping, developing, and evaluating programs</H2>
        <ProjectP>
        Guided by our goals, we initially designed our public programs using worksheets to ensure inclusivity, allowing each member to present ideas, followed by questions and consensus-based voting. Both print-friendly and digital worksheets were distributed before meetings to accommodate everyone, including quieter members.
         </ProjectP>
         
        <ProjectP>

        Over the years, our focus has expanded to include design education, industry insights, and skill development, with programs shaped by volunteer members based on their interests and availability. We gather regular feedback through quick social media polls, event activities, and one-on-one discussions. 

        </ProjectP>
       
      </ContentSection>
      <CaptionedImage caption={"Worksheets help us detail community programs in an inclusive way"} imageSrc={worksheetsImage}></CaptionedImage>
      <CaptionedImage caption={"A community member shares thoughts on what belonging means to them"} imageSrc={workboardImage}></CaptionedImage>
      
      <ContentSection>
        <H2> Growing together and fostering resilience</H2>
        <ProjectP>
        The true essence of our community shines through open sharing and learning that occur when members gather. Influenced by member interests and changing socio-economic conditions, our work constantly evolves. As we grow, we continuously seek ways to foster resilience and adapt effectively. We ask “What structure/program/topic serves us now?” as we strive for balance between responding to the times and being focused on our vision.

        </ProjectP>
        </ContentSection>
      <CaptionedImage caption={"Community members gather at a local musuem to discuss new pathways for design after the people's protests of 2022"} imageSrc={gatheringImage}></CaptionedImage>
      
    </ProjectContainer>
  );
}
